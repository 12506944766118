<template>
  <s-datepicker
    :modelValue="value"
    :format="format ?? 'yyyy-MM-dd'"
    :language="language ?? 'ko'"
    :useFullMonthName="false"
    :placeholder="placeholder ?? 'YYYY-MM-DD'"
    :isInline="false"
    :useMondayFirst="false"
    :isDisabled="disabled"
    :isRequired="required"
    :isTypeable="false"
    :useUtc="false"
    :minimumView="minimumView ?? 'day'"
    maximumView="year"
    :size="size ?? 'sm'"
    validationState="none"
    :class="wrapperClass"
    :inputClass="inputClass"
    :disabledDates="disabledDates"
    :openDate="value ?? new Date()"
    @update:modelValue="handleModelUpdate"
  />

  <st-error-message :name="name ?? ''" :showError="showFieldError" />
</template>

<script setup lang="ts">
import { ref, toRefs, watch } from 'vue';

import StErrorMessage from '@/components/validation/st-error-message.vue';
import useValidation from '@/composables/useValidation';
import type { DatePickerProps } from '@/types/common/form.type';

const props = defineProps<DatePickerProps>();

const { rules } = toRefs(props);

const emit = defineEmits<{
  'update:modelValue': [v: string | Date | undefined];
}>();

const value = ref(props.modelValue);

const setFieldValue = ref<(value: string | Date | undefined) => void>();
const showFieldError = ref(false);

if (props.name) {
  const { setValue, showError } = useValidation<string | Date | undefined>({
    fieldName: props.name,
    rules,
    value
  });

  setFieldValue.value = setValue;

  watch(
    () => showError.value,
    (v: boolean) => {
      showFieldError.value = v;
    }
  );
}

watch(
  () => props.modelValue,
  (v: string | Date | undefined) => {
    value.value = v;
  }
);

const handleModelUpdate = (e: string | Date | undefined) => {
  if (setFieldValue.value) {
    setFieldValue.value(e);
  }
  emit('update:modelValue', e);
};
</script>
